import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22463310"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "download"]
const _hoisted_2 = {
  class: "chat-message-file__icon",
  xmlns: "http://www.w3.org/2000/svg",
  width: "17",
  height: "20"
}
const _hoisted_3 = ["fill"]
const _hoisted_4 = { class: "chat-message-file__content" }
const _hoisted_5 = { class: "chat-message-file__name" }
const _hoisted_6 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "19"
}
const _hoisted_7 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.downloadLink,
    download: _ctx.fileName,
    class: _normalizeClass([
      'chat-message-file',
      _ctx.answer && 'chat-message-file--answer',
      _ctx.breakpoints.isDesktop && 'chat-message-file--desktop',
    ])
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        fill: [_ctx.answer ? '#fff' : '#0667DF'],
        d: "M4.677 19.999C2.9 19.985.2 17.879.8 15.289V4.509C.1 2.32 1.994.15 4.468 0H9.75c.45.1.456.207.455.459v3.22c0 1.832 1.474 3.421 3.304 3.511.423 0 .793 0 1.121.06H15.83c.76 0 .486-.05.715-.05.247.02.446.022.446.269v8.04c.009 2.467-1.861 4.476-4.442 4.49H4.677zm.01-6.359c.003.86.513.743.742.75h5.381c.41-.007.739.11.742-.75a.742.742 0 0 0-.742-.741H5.428c-.228 0-.74.331-.741.74v.001zm0-4.99c.001.95.513.741.742.74h3.342c.409.001.741.21.742-.74a.755.755 0 0 0-.742-.75H5.428c-.238.008-.737.34-.741.749v.001zm8.955-3.091a2.016 2.016 0 0 1-1.998-2.017V.905a.473.473 0 0 1 .813-.334l3.982 4.187a.477.477 0 0 1-.339.806h-.691a140 140 0 0 1-1.767-.006v.001z"
      }, null, 8, _hoisted_3)
    ])),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.fileName), 1)
    ]),
    _createElementVNode("button", {
      class: _normalizeClass([
        'chat-message-file__download',
        _ctx.answer && 'chat-message-file__download--answer',
      ])
    }, [
      (_openBlock(), _createElementBlock("svg", _hoisted_6, [
        _createElementVNode("path", {
          "fill-rule": "evenodd",
          fill: [_ctx.answer ? '#fff' : '#0C1229'],
          d: "M15.575 18.732H4.434C1.989 18.732 0 16.788 0 14.4V9.629c0-2.383 1.985-4.321 4.424-4.321h.943c.414 0 .75.328.75 1.102 0 .035-.336.363-.75.363h-.943c-1.612 0-2.924 1.767-2.925 2.856V14.4c0 1.581 1.317 2.867 2.935 2.867h11.141c1.612 0 2.924-1.282 2.924-2.857V9.639c0-1.049-1.316-2.866-2.934-2.866h-.933c-.414 0-.75-.328-.75-.363 0-.774.336-1.102.75-1.102h.933c2.535 0 4.434 1.943 4.434 4.33v4.772c0 2.383-1.849 4.322-4.424 4.322zm-5.044-5.037h-.001a.769.769 0 0 1-.82.158.741.741 0 0 1-.241-.158h-.001l-2.916-2.86c-.292-.287-.291.015.002-1.035a.761.761 0 0 1 1.061.002l1.634 1.603V1.418c0-.288.336-.733.75-.733.415 0 .75.445.75.733v9.987l1.635-1.603a.761.761 0 0 1 1.061-.002c.293 1.05.294.748.002 1.035l-2.916 2.86z"
        }, null, 8, _hoisted_7)
      ]))
    ], 2)
  ], 10, _hoisted_1))
}