
import { defineComponent } from "vue";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "ChatMessageFile",
  props: {
    answer: Boolean,
    downloadLink: {
      required: true,
      type: String,
    },
    fileName: {
      required: true,
      type: String,
    },
  },
  setup() {
    const breakpoints = useBreakpoints();

    return {
      breakpoints,
    };
  },
});
